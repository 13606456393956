import './Footer.css';
import '../App.css';
import { IoMdMail } from "react-icons/io";
import { FaGithub } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

export function Footer({ darkMode }) {
  return (
    <footer>
      <div className="svgShape">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill={darkMode ? '#16577d' : '#1d1d1d'} fill-opacity="1" d="M0,64L80,53.3C160,43,320,21,480,32C640,43,800,85,960,112C1120,139,1280,149,1360,154.7L1440,160L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path></svg>
      </div>
      <div className="footerContainer">
        <div className="topsection">
          <div className="logo">
            <img src="images/logo.png" alt="logo" />
            <p>Lucas Tchitdjian</p>
          </div>
        </div>
        <div className="bottomsection">
          <div className="copyright"><h3>Copyright © 2024 - <span>Tous droits réservés </span></h3></div>
          <div className="links">
            <a href="mailto:tchitdjian.lucas@gmail.com"><IoMdMail /></a>
            <a href="https://github.com/LucasTchitdjian"><FaGithub /></a>
            <a href="https://www.linkedin.com/in/lucas-tchitdjian/"><FaLinkedin /></a>
          </div>
        </div>
      </div>
    </footer>
  );
}