import './Skills.css';
import { Element } from 'react-scroll';
import { useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';

export function Skills() {

    const [animateSkills, setAnimateSkills] = useState(false);
    const skillsRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                entry.target.classList.add("show");
                setAnimateSkills(true);
              }
            });
          }, { threshold: 0.1 }
        );
      
        // Capture the current value of skillsRef.current inside the effect
        const currentSkillsRef = skillsRef.current; 
        if (currentSkillsRef) {
          observer.observe(currentSkillsRef);
        }
      
        // Use the captured value in the cleanup function
        return () => {
          if (currentSkillsRef) { 
            observer.unobserve(currentSkillsRef);
          }
        };
      }, []); // Empty dependency array ensures this effect runs only once
      

    const skillVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    };

    return (
        <Element name="skills" className="skills-element">
            <div className='skills-wrapper'>
                <h1 className='title'>Compétences techniques</h1>
                <div ref={skillsRef} className='skills-experience'>
                    <motion.div className="skills">
                        <motion.div
                            initial="hidden"
                            animate={animateSkills ? "visible" : "hidden"}
                            variants={skillVariants}
                            transition={{ duration: 1, delay: 0.5 }}
                            className="skill">
                            <div className="image">
                                <div className="html"><img src='https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/HTML5_logo_and_wordmark.svg/197px-HTML5_logo_and_wordmark.svg.png' alt='HTML' /></div>
                            </div>
                            <p>HTML5</p>
                        </motion.div>
                        <motion.div initial="hidden"
                            animate={animateSkills ? "visible" : "hidden"}
                            variants={skillVariants}
                            transition={{ duration: 1, delay: 1 }}
                            className="skill">
                            <div className="image">
                                <div className="html"><img src='https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/langfr-195px-CSS3_logo_and_wordmark.svg.png' alt='CSS3' /></div>
                            </div>
                            <p>CSS3</p>
                        </motion.div>
                        <motion.div initial="hidden"
                            animate={animateSkills ? "visible" : "hidden"}
                            variants={skillVariants}
                            transition={{ duration: 1, delay: 1.5 }}
                            className="skill">
                            <div className="image">
                                <div className="html"><img src='https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Unofficial_JavaScript_logo_2.svg/197px-Unofficial_JavaScript_logo_2.svg.png' alt='Javascript' /></div>
                            </div>
                            <p>JavaScript</p>
                        </motion.div>
                        <motion.div initial="hidden"
                            animate={animateSkills ? "visible" : "hidden"}
                            variants={skillVariants}
                            transition={{ duration: 1, delay: 2 }}
                            className="skill">
                            <div className="image">
                                <div className="react"><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/30/React_Logo_SVG.svg/langfr-330px-React_Logo_SVG.svg.png" alt="react" /></div>
                            </div>
                            <p>ReactJS</p>
                        </motion.div>
                        <motion.div initial="hidden"
                            animate={animateSkills ? "visible" : "hidden"}
                            variants={skillVariants}
                            transition={{ duration: 1, delay: 2.5 }}
                            className="skill">
                            <div className="image">
                                <div className="html"><img src='https://www.vectorlogo.zone/logos/firebase/firebase-icon.svg' alt='firebase' /></div>
                            </div>
                            <p>Firebase</p>
                        </motion.div>
                        <motion.div initial="hidden"
                            animate={animateSkills ? "visible" : "hidden"}
                            variants={skillVariants}
                            transition={{ duration: 1, delay: 3 }}
                            className="skill">
                            <div className="image">
                                <div className="next-js"><img src='https://static-00.iconduck.com/assets.00/nextjs-icon-2048x1234-pqycciiu.png' alt='next-js'/></div>
                            </div>
                            <p>Next JS</p>
                        </motion.div>
                        <motion.div initial="hidden"
                            animate={animateSkills ? "visible" : "hidden"}
                            variants={skillVariants}
                            transition={{ duration: 1, delay: 3.5 }}
                            className="skill">
                            <div className="image">
                                <div className="html"><img src='https://upload.wikimedia.org/wikipedia/commons/thumb/c/cf/Angular_full_color_logo.svg/768px-Angular_full_color_logo.svg.png' alt='angular'/></div>
                            </div>
                            <p>Angular</p>
                        </motion.div>
                        <motion.div initial="hidden"
                            animate={animateSkills ? "visible" : "hidden"}
                            variants={skillVariants}
                            transition={{ duration: 1, delay: 4 }}
                            className="skill">
                            <div className="image">
                                <div className="mysql"><img src="https://upload.wikimedia.org/wikipedia/fr/thumb/6/62/MySQL.svg/langfr-330px-MySQL.svg.png" alt="mysql" /></div>
                            </div>
                            <p>MySQL</p>
                        </motion.div>
                        <motion.div initial="hidden"
                            animate={animateSkills ? "visible" : "hidden"}
                            variants={skillVariants}
                            transition={{ duration: 1, delay: 4.5 }}
                            className="skill">
                            <div className="image">
                                <div className="node"><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/27/PHP-logo.svg/1920px-PHP-logo.svg.png" alt="php" /></div>
                            </div>
                            <p>Php</p>
                        </motion.div>
                        <motion.div initial="hidden"
                            animate={animateSkills ? "visible" : "hidden"}
                            variants={skillVariants}
                            transition={{ duration: 1, delay: 5 }}
                            className="skill">
                            <div className="image">
                                <div className="express"><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/62/Ruby_On_Rails_Logo.svg/180px-Ruby_On_Rails_Logo.svg.png" alt="RubyOnRails" /></div>
                            </div>
                            <p>Ruby on Rails</p>
                        </motion.div>
                    </motion.div>
                </div>
            </div>
        </Element>
    )
}