import './Projects.css';
import { FaArrowRight } from "react-icons/fa";
import { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import mooviesTracker from './CaptureMooviesTracker.png';

const projectsVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
};

export function Projects({ darkMode }) {

    const [animateProjects, setAnimateProjects] = useState(false);
    const projectsRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setAnimateProjects(true);
                }
            },
            {
                rootMargin: '0px',
                threshold: 0.1,
            }
        );

        const currentRef = projectsRef.current;

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, []);

    return (
        <div className='projects-wrapper' ref={projectsRef}>
            <div className="projects">
                <h1>Mes derniers projets</h1>
                <motion.div className="cards">
                    <motion.div className="card" initial="hidden"
                        animate={animateProjects ? "visible" : "hidden"}
                        variants={projectsVariants}
                        transition={{ duration: 1, delay: 0.5 }}>
                        <div className="top">
                            <h3>Reactonauts</h3>
                            <a href="https://spacetourismlucas.netlify.app">
                                <FaArrowRight />
                            </a>
                        </div>
                        <div className="img">
                            <img src="images/CaptureSpaceTourism.jpg" alt="projet 1" />
                        </div>
                        <p>Application web multi pages présentant le futur du tourisme spatial. Réalisé à l’aide du framework REACT JS.</p>
                    </motion.div>
                    <motion.div className="card" initial="hidden"
                        animate={animateProjects ? "visible" : "hidden"}
                        variants={projectsVariants}
                        transition={{ duration: 1, delay: 1 }}>
                        <div className="top">
                            <h3>RESTful Nations</h3>
                            <a href="https://restcountriesapi-lucas.netlify.app">
                                <FaArrowRight />
                            </a>
                        </div>
                        <div className="img">
                            <img src="images/ProjetAPIRestCountries.jpg" alt="projet 2" />
                        </div>
                        <p>Application web utilisant une api de type REST qui permet de rechercher un pays en effectuant un tri par continent .Réalisé aussi à l’aide de REACT JS</p>
                    </motion.div>
                    <motion.div className="card" initial="hidden"
                        animate={animateProjects ? "visible" : "hidden"}
                        variants={projectsVariants}
                        transition={{ duration: 1, delay: 1.5 }}>
                        <div className="top">
                            <h3>What to watch</h3>
                            <a href="https://mooviestracker.firebaseapp.com/now-playing">
                                <FaArrowRight />
                            </a>
                        </div>
                        <div className="img">
                            <img src={mooviesTracker} alt="projet 3" />
                        </div>
                        <p>Cette plateforme permettra à chaque utilisateur de créer un profil unique afin de suivre et de gérer sa liste de contenus visionnés.</p>
                    </motion.div>
                </motion.div>
            </div>
            <motion.div ref={projectsRef} className="projects-swipper" initial="hidden"
                animate={animateProjects ? "visible" : "hidden"}
                variants={projectsVariants}
                transition={{ duration: 1, delay: 0.5 }}>
                <swiper-container style={darkMode ? { "--swiper-navigation-color": "#000", "--swiper-pagination-color": "#000" } : { "--swiper-navigation-color": "#fff", "--swiper-pagination-color": "#fff" }} space-between="10" slides-per-view="1" pagination="true" navigation="true">
                    <swiper-slide>
                        <div className="cards-swipper">
                            <motion.div className="card-swipper" >
                                <div className="top">
                                    <h3>Projet voyage spacial</h3>
                                    <a href="https://spacetourismlucas.netlify.app">
                                        <FaArrowRight />
                                    </a>
                                </div>
                                <div className="img">
                                    <img src="images/CaptureSpaceTourism.jpg" alt="projet 1" />
                                </div>
                            </motion.div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div className="cards-swipper">
                            <motion.div className="card-swipper">
                                <div className="top">
                                    <h3>Projet api REST de pays</h3>
                                    <a href="https://restcountriesapi-lucas.netlify.app">
                                        <FaArrowRight />
                                    </a>
                                </div>
                                <div className="img">
                                    <img src="images/ProjetAPIRestCountries.jpg" alt="projet 2" />
                                </div>
                            </motion.div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div className="cards-swipper">
                            <motion.div className="card-swipper">
                                <div className="top">
                                    <h3>Application de suivis films et séries</h3>
                                    <a href="https://www.mooviestracker.netlify.app"> 
                                        <FaArrowRight />
                                    </a>
                                </div>
                                <div className="img">
                                    <img src={mooviesTracker} alt="projet 3" />
                                </div>
                            </motion.div>
                        </div>
                    </swiper-slide>
                </swiper-container>
            </motion.div>
        </div>
    );
}
