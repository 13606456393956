import './Faq.css';
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';

const variants = {
    open: {
        transition: { staggerChildren: 0.5 },
    },
    closed: {
        transition: { staggerChildren: 0.2, staggerDirection: -1 },
    },
};

const itemVariants = {
    open: {
        y: 0,
        opacity: 1,
        transition: {
            y: { stiffness: 1000, velocity: -100 },
        },
    },
    closed: {
        y: 50,
        opacity: 0,
        transition: {
            y: { stiffness: 1000 },
        },
    },
};

export function Faq({ darkMode }) {
    // État pour suivre l'index de la FAQ ouverte
    const [openFaqIndex, setOpenFaqIndex] = useState(null);
    const [animateFaq, setAnimateFaq] = useState(false);
    const faqRef = useRef(null);

    const handleShowAnswer = index => {
        // Ferme la FAQ si elle est déjà ouverte, sinon ouvre la nouvelle FAQ
        setOpenFaqIndex(openFaqIndex === index ? null : index);
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setAnimateFaq(true);
                }
            },
            {
                rootMargin: '0px',
                threshold: 0.1,
            }
        );

        const currentFaq = faqRef.current;

        if (currentFaq) {
            observer.observe(currentFaq);
        }

        return () => {
            if (currentFaq) {
                observer.unobserve(currentFaq);
            }
        };
    }, []);

    const faqs = [
        {
            question: "Pouvez-vous vous présenter dans les grandes lignes ?",
            answer: "Je m'appelle Lucas Tchitdjian, j'ai 24 ans et je suis spécialisé en développement front-end avec une expertise en React. J'ai acquis une expérience précieuse lors de mon année en alternance chez Superlative Apps, où j'ai contribué au développement de Chooz'it, une application innovante aidant les utilisateurs à trouver, centraliser, organiser et comparer différents produits en ligne. Cette expérience, combinée à ma formation à l'ISCOD préparant au titre de Concepteur et Développeur d'Applications, a renforcé mes compétences en développement web, notamment en utilisant le framework React. Je suis motivé à poursuivre ma carrière en tant que développeur front-end React, apportant mon expertise technique et ma passion pour le développement d'applications web intuitives et performantes."
        },
        {
            question: "Quelles sont vos spécialités en tant que développeur web ?",
            answer: "Après une formation en alternance en tant que concepteur et développeur d'applications WEB. J'ai décide de me spécialiser dans l'utilisation de React, un framework JavaScript qui me permet de créer des applications web modernes et performantes."
        },
        {
            question: "Quelles sont vos compétences ?",
            answer: "Au niveau des compétences techniques, j'ai acquis une maîtrise avancée en JavaScript, notamment dans l'utilisation de fonctions asynchrones. En React, j'ai efficacement utilisé divers hooks tels que useState et useEffect. En Laravel, j'ai développé ma capacité à communiquer avec des bases de données et à utiliser le moteur de template Blade."
        },
        {
            question: "Quelles formations avez-vous suivi ?",
            answer: "J'ai suivi un bootcamp de 1mois et demi au sein de la structure LE WAGON à marseille qui ma permit d'acquérir les bases de la conception au déploiement de projets d'applications web. J'ai également suivi une formation en alternance au sein de l'ISCOD à distance qui m'a permit d'acquérir un large éventail de compétences en développement fullstack, avec un focus particulier sur les technologies front-end comme HTML5, CSS3, et surtout ReactJS, ce qui m'a permis de développer des interfaces utilisateur complexes et interactives. En back-end, j'ai acquis des compétences en NodeJS et Express, ainsi que des connaissances approfondies en bases de données avec MongoDB et MySQL, ce qui me permet de comprendre l'architecture globale des applications web. La sécurité étant un aspect crucial, j'ai également appris à mettre en œuvre des mesures de sécurité essentielles comme JWT, XSS, et CSRF. Enfin, l'intégration des méthodes agiles et des compétences en gestion de projet me prépare à collaborer efficacement au sein d'équipes de développement"
        },
        {
            question: "Quelles sont vos qualités ?",
            answer: "Organisé : Je gère mon temps et mes ressources avec efficacité, permettant une progression fluide vers les objectifs. Réfléchi : Je prends le temps d'analyser les options avant d'agir, anticipant les défis pour mieux les surmonter.Curieux : Ma soif de connaissance m'incite à explorer constamment de nouvelles idées et technologies, enrichissant ainsi mes projets. Rigoureux : Mon attention aux détails assure des résultats de haute qualité et précis.Adaptabilité : Je m'adapte facilement aux changements, ce qui est essentiel dans un monde en constante évolution.Autodidacte : Mon initiative personnelle dans l'apprentissage démontre ma capacité à développer continuellement de nouvelles compétences."
        },
        {
            question: "Quelles sont vos hobbies ?",
            answer: "J'aime faire du sport comme du tennis ou du basket-ball. J'aime également regarder des films et des séries. Enfin la musique est une passion pour moi, j'aime écouter de la musique de tout genre."
        }
    ];

    return (
        <div className="faq-wrapper">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path className='svg-skills' fill={darkMode ? '#eee' : '#1d1d1d'} fill-opacity="1" d="M0,0L120,53.3C240,107,480,213,720,218.7C960,224,1200,128,1320,80L1440,32L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path></svg>
            <div className='faq-container'>
                <h1>Foire aux questions</h1>
                <motion.ul ref={faqRef} initial="closed" animate={animateFaq ? 'open' : 'closed'} variants={variants}>
                    {faqs.map((faq, index) => (
                        <motion.div className="faq-bloc" key={index}>
                            <motion.li onClick={() => handleShowAnswer(index)} variants={itemVariants}>
                                <h2>{faq.question}</h2>
                                {openFaqIndex === index ?
                                    <IoIosArrowDown onClick={(e) => { e.stopPropagation(); handleShowAnswer(index); }} />
                                    : <IoIosArrowForward onClick={(e) => { e.stopPropagation(); handleShowAnswer(index); }} />
                                }
                            </motion.li>
                            {openFaqIndex === index && <motion.p
                                className='faq-answer'
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5 }}
                            >
                                {faq.answer}
                            </motion.p>}
                        </motion.div>
                    ))}
                </motion.ul>
            </div>
        </div>
    );
}
