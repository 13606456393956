import './DownloadCv.css';
import { IoDocumentText } from "react-icons/io5";
import { FaDownload } from "react-icons/fa";
import  Resume  from '../CV_Lucas_Tchitdjian_Développeur_WEB_Front-end_Junior.pdf';
import { motion } from 'framer-motion';
import { useState, useEffect, useRef } from 'react';

const downloadVariants = {
    hidden: { opacity: 0 },
    visible: { 
        opacity: 1,
        transition: {
            duration: 1,
            delay: 0.5,
        }
    }
};

export function DownloadCv() {

    const [animateDownload, setAnimateDownload] = useState(false);
    const downloadRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setAnimateDownload(true);
                }
            },
            {
                rootMargin: '0px',
                threshold: 0.1,
            }
        );

        const currentRef = downloadRef.current;

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, []);

    return (
        <div className='downloadCv-wrapper' ref={downloadRef}>
            <motion.div className='downloadCv-container' variants={downloadVariants} initial="hidden" animate={animateDownload ? 'visible' : 'hidden'}>
                <div className="left">
                    <div className="icon"><IoDocumentText /></div>
                    <div className="question">
                        <h2>Mon travail vous plait ?</h2>
                        <p>N'hésitez pas à consulter mon CV !</p>
                    </div>
                </div>
                <div className="right">
                    <a href={Resume} download="CV_Lucas_Tchitdjian_Développeur_WEB_Front-end_Junior" class="download-button"><FaDownload />Télécharger le document </a>
                </div>
            </motion.div>
        </div>
    );
}
