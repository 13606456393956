import './Hero.css';
import '../App.css';
import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { FaLinkedin } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { motion } from "framer-motion";

const textVariants = {
    initial: {
        x: -500,
        opacity: 0,
    },
    animate: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 0.5,
            staggerChildren: 0.1,
        },
    },
};

export function Hero() {

    const links = [
        { name: 'Linkedin', icon: <FaLinkedin />, href: 'https://www.linkedin.com/in/lucas-tchitdjian/' },
        { name: 'Github', icon: <FaGithub />, href: 'https://github.com/LucasTchitdjian' },
        { name: 'Mail', icon: <IoMdMail />, href: 'mailto:tchitdjian.lucas@gmail.com' }
    ];

    const texteEntier = `Développeur front-end React passionné. Je construis des applications web performantes et intuitives qui améliorent l'expérience utilisateur.`;
    const [texte, setTexte] = useState('');

    useEffect(() => {
        if (texte.length < texteEntier.length) {
            const timeoutId = setTimeout(() => {
                setTexte(texteEntier.substring(0, texte.length + 1));
            }, 50   );
            return () => clearTimeout(timeoutId);
        }
    }, [texte, texteEntier]);

    return (
        <div className="hero-wrapper">
            <motion.div className="hero" initial="initial" animate="animate" variants={textVariants}>
                <div
                    className="left"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1 }}><motion.h1
                        initial={{ x: -100 }}
                        animate={{ x: 0 }}
                        transition={{ type: "spring", duration: 0.5 }}
                        variants={textVariants}
                    >
                        {texte.split('\n').map((line, index, array) => (
                            <React.Fragment key={index}>
                                {line}
                                {/* Ajoutez un <br> seulement s'il ne s'agit pas de la dernière ligne */}
                                {index < array.length - 1 ? <br /> : ''}
                            </React.Fragment>
                        ))}
                        <motion.span
                            animate={{ opacity: [0, 1] }}
                            transition={{ repeat: Infinity, duration: 0.8, ease: "easeInOut" }}
                            variants={textVariants}
                        >
                            |
                        </motion.span>
                    </motion.h1>

                    <div className="links">
                        <motion.ul variants={textVariants} initial="closed" animate="open">
                            {links.map((link, index) => (
                                <motion.li variants={textVariants} key={index}>
                                    <Button variants={textVariants} href={link.href} variant="text" className='links-btn'>{link.name}{link.icon}</Button>
                                </motion.li>
                            ))}
                        </motion.ul>
                    </div>
                </div>
                <div className="right">
                    <div className="profil-img-container">
                        <motion.img variants={textVariants} src="images/imageprofil.jpg" alt="" />
                    </div>
                </div>
            </motion.div>
        </div>
    );
}