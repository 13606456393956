import './Formations.css';
import { useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';

export function Formations() {

    const [animateFormations, setAnimateFormations] = useState(false);
    const formationsRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setAnimateFormations(true);
                }
            },
            {
                rootMargin: '0px',
                threshold: 0.1,
            }
        );

        const currentRef = formationsRef.current;

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, []);

    const formationsVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    };

    return (
        <div className="formationswrapper">
            <h1>Formations</h1>
            <div className="formationscontainer" ref={formationsRef}>
                <motion.div className="formation" initial="hidden"
                    animate={animateFormations ? "visible" : "hidden"}
                    variants={formationsVariants}
                    transition={{ duration: 1, delay: 0.5 }}>
                    <div className="top">
                        <h3>Concepteur développeur d'applications en alternance (niveau 6) - ISCOD</h3>
                        <img src="https://ad-education.com/wp-content/uploads/2022/07/logo-iscod-a-1-thumbnail-portrait-jpg.webp" alt="iscod" />
                    </div>
                    <div className="formationInfo">
                        <div className="years">Janvier 2023 - Décembre 2023</div>
                        <div className="formation-title">
                            <p>Formation fullstack: développement d'applications web et mobiles.</p>
                            <p><strong>Frontend: </strong> HTML5, CSS3, JavaScript, ReactJS.</p>
                            <p><strong>Backend: </strong> NodeJS, Express</p>
                            <p>Compétences en bases de données (MongoDB, MySQL) et API RESTful. </p>
                            <p>Focus sur la sécurité (JWT, XSS, CSRF).</p>
                            <p>Gestion de projet et méthodes agiles intégrées.</p>
                        </div>
                    </div>
                </motion.div>
                <motion.div className="formation" initial="hidden"
                    animate={animateFormations ? "visible" : "hidden"}
                    variants={formationsVariants}
                    transition={{ duration: 1, delay: 0.5 }}>
                    <div className="top">
                        <h3>Bootcamp Développeur WEB (niveau 6) - LE WAGON</h3>
                        <img src="https://etudestech.com/wp-content/uploads/2021/07/lewagon-logo-square-b6124eb974be375884558e4464efce48a9b5664f18422768156364363ecdd1fc.png.webp" alt="lewagon" />
                    </div>
                    <div className="formationInfo">
                        <div className="years">Novembre 2021 - Janvier 2022</div>
                        <div className="formation-title">
                            <p>Développement Full-Stack : Formation complète en développement web,
                                de la conception d'interfaces utilisateur modernes et interactives (frontend)
                                à la création de la logique applicative robuste et évolutive (backend).
                                Expertise technique : Maîtrise des langages et outils essentiels du
                                développement web moderne :</p>
                            <p><strong>Front-end: </strong> HTML, CSS, JavaScript (ES6+), React, Vue.js, Angular</p>
                            <p><strong>Back-end:</strong> Node.js, Python, Ruby, SQL</p>
                            <p><strong>Outils:</strong> Git, gestionnaires de paquets (npm, yarn, pip), outils de
                            développement (VS Code, etc.).</p>
                        </div>
                    </div>
                </motion.div>
                <motion.div className="formation" initial="hidden"
                    animate={animateFormations ? "visible" : "hidden"}
                    variants={formationsVariants}
                    transition={{ duration: 1, delay: 0.5 }}>
                    <div className="top">
                        <h3>Chef de Projet web en alternance (niveau 7) - g4 </h3>
                        <img className='g4' src="https://www.institut-g4.fr/wp-content/uploads/2022/11/G4-couleur-2.png" alt="g4" />
                    </div>
                    <div className="formationInfo">
                        <div className="years">Septembre 2019 - Septembre 2020</div>
                        <div className="formation-title">
                            <p><strong>Conception Technologique:</strong> (Génie Logiciel, Merise, UML) / Outils :
                                (Intégration / Responsive Framework Outils du développeur)</p>
                            <p><strong>Programmation: </strong> (Front End Dev SQL PHP Node.JS) / Administration des SI :
                                (Systèmes Réseaux, XML)</p>
                            <p><strong>Management de projet: </strong> (Management de projet, Management de la
                                qualité ,Planiﬁcation) Efficacité personnelle: (Alternance et compétences
                                ,Communication orale, Anglais)</p>
                        </div>
                    </div>
                </motion.div>
            </div>
        </div>
    )
}