import './About.css';
import '../App.css';
import { useEffect, useState, useRef } from 'react';
import { FaArrowUp } from "react-icons/fa";
import { AnimatePresence, motion } from 'framer-motion';


export function About({ darkMode }) {

    const [showScrollButton, setShowScrollButton] = useState(false);
    const [animateAbout, setAnimateAbout] = useState(false);
    const aboutRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setAnimateAbout(true);
                }
            },
            {
                rootMargin: '0px',
                threshold: 0.1,
            }
        );
    
        const currentAbout = aboutRef.current;
    
        if (currentAbout) {
            observer.observe(currentAbout);
        }
    
        const checkScrollPosition = () => {
            const about = document.querySelector('.about');
            if (!about) return;
    
            const position = about.getBoundingClientRect().top + window.scrollY;
            if (window.scrollY > position) {
                setShowScrollButton(true);
            } else {
                setShowScrollButton(false);
            }
        };
    
        window.addEventListener('scroll', checkScrollPosition);
    
        return () => {
            if (currentAbout) {
                observer.unobserve(currentAbout);
            }
            window.removeEventListener('scroll', checkScrollPosition);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    const aboutVariants = {
        hidden: { 
            opacity: 0,
            x: -500
        },
        visible: { 
            opacity: 1,
            x: 0
        }
    };

    return (
        <div className="about-wrapper">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path className='svg-skills' fill={darkMode ? '#eee' : '#1d1d1d'} fill-opacity="1" d="M0,0L120,53.3C240,107,480,213,720,218.7C960,224,1200,128,1320,80L1440,32L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path></svg>
            <div className="about" ref={aboutRef}>
                <motion.h1 animate={animateAbout ? "visible" : "hidden"} variants={aboutVariants} transition={{ duration: 1, delay: 0.5 }}>A propos de moi</motion.h1>
                <motion.p animate={animateAbout ? "visible" : "hidden"} variants={aboutVariants} transition={{ duration: 1, delay: 0.5 }}
                >Développeur front-end REACT avec 3 ans d'expérience en conception d'interfaces utilisateur, je suis à la recherche de nouveaux défis au sein d'une entreprise dynamique. Mon expérience en startup et mes projets personnels témoignent de ma capacité à créer des solutions web innovantes et performantes.
                </motion.p>
                <AnimatePresence>
                    {showScrollButton && (
                        <motion.button
                            className='scrollTop-Btn'
                            onClick={scrollToTop}
                            initial={{ x: '10vw' }}
                            whileHover={{ backgroundColor: 'black' }} // Style de survol
                            animate={{ x: 0 }}
                            exit={{ x: '10vw' }}
                            transition={{ duration: 0.5, ease: 'easeInOut' }}
                            style={{ position: 'fixed', bottom: '30px', right: '20px', zIndex: '999', backgroundColor: '#16577d'}}>
                            <FaArrowUp style={{ color: 'white', cursor: 'pointer' }} />
                        </motion.button>
                    )}
                </AnimatePresence>
            </div>
        </div>
    )
}