import './Header.css';
import '../App.css';
import { FaCogs } from "react-icons/fa";
import { FaCode } from "react-icons/fa";
import { MdPermContactCalendar } from "react-icons/md";
import { useState } from 'react';
import { GiHamburgerMenu } from "react-icons/gi";
import { IoIosClose } from "react-icons/io";
import { FaMoon } from "react-icons/fa";
import { FaSun } from "react-icons/fa";
import { AiFillExperiment } from "react-icons/ai";
import { FaGraduationCap } from "react-icons/fa";
import { motion } from "framer-motion";

const variants = {
  open: {
    transition: {
      staggerChildren: 0.3,
      stiffness: 1000,
    },
  },
  closed: {
    transition: {
      staggerChildren: 0.1,
      staggerDirection: -1,
      damping: 3000,
    },
  },
};

const itemVariants = {
  open: {
    y: 0,
    opacity: 1,
  },
  closed: {
    y: 50,
    opacity: 0,
  },
};

export function Header({ darkMode, setDarkMode }) {

  const menuItems = [
    { name: "Compétences", selector: ".skills-experience", Icon: FaCogs },
    { name: "Expériences", selector: ".experienceswrapper", Icon: AiFillExperiment },
    { name: "Formations", selector: ".formationswrapper", Icon: FaGraduationCap },
    { name: "Projets", selector: ".projects", Icon: FaCode },
    { name: "Contact", selector: ".contact", Icon: MdPermContactCalendar },
  ];

  const [openHambugerMenu, setOpenHambugerMenu] = useState(false);

  const scrollToSection = (selector) => {
    const section = document.querySelector(selector);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleHamburgerMenu = () => {
    setOpenHambugerMenu(!openHambugerMenu);
  };

  const handleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const handleLinkClick = (selector) => {
    scrollToSection(selector);
    handleHamburgerMenu(); // Ceci basculera l'état du menu hamburger en fermé.
  };

  return (
    <header>
      <motion.ul initial="closed" animate="open" variants={variants}>
        <div className="logo">
          <li className='logo'><img src="images/logo.png" alt="Logo" /></li>
        </div>
        <div className="menu">
          {menuItems.map((item, index) => (
            <motion.li variants={itemVariants} whileHover={{
              transform: "translateY(-5px)",
              transition: { duration: 0.33, ease: "easeInOut" },
              color: "black",
              cursor: "pointer"
            }} className='menu' key={index} onClick={() => scrollToSection(item.selector)}><item.Icon /><span>{item.name}</span></motion.li>
          ))}
          <button onClick={handleDarkMode}>{darkMode ? <FaMoon /> : <FaSun />}</button>
          <li className='hamburger-menu'>
            <GiHamburgerMenu on onClick={handleHamburgerMenu} />
          </li>
        </div>
      </motion.ul>
      <motion.nav
        className={`mobile-menu${openHambugerMenu ? ' open' : ''}`}
        initial={false}
        animate={openHambugerMenu ? "open" : "closed"}
        variants={variants} // Appliquer les variants de transition au conteneur nav
      >
        <motion.ul>
          {menuItems.map((item, index) => (
            <motion.li
              key={index}
              variants={itemVariants}
              // Appliquer les variants de transition aux éléments li
              onClick={() => handleLinkClick(item.selector)}
            >
              <item.Icon />{item.name}
            </motion.li>
          ))}
          <IoIosClose onClick={handleHamburgerMenu} />
        </motion.ul>
      </motion.nav>
      {openHambugerMenu && <div className="menu-overlay" onClick={handleHamburgerMenu}></div>}
    </header>
  );
}